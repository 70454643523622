import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy mobile device encryption.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`Mobile devices such as smart phone and tablets offer great flexibility
and improved productivity for employees. However, they can also create
added risk and potential targets for data loss. As such, there use must
be in alignment with appropriate standards and encryption technology
should be used when possible.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`This document describes Information Security’s requirements for
encrypting data at rest on Data Migrators mobile devices.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy applies to any mobile device issued by Data Migrators or
used for Data Migrators business which contains stored data owned by
Data Migrators.`}</p>
    <h2>{`Policy`}</h2>
    <p>{`All mobile devices containing stored data owned by Data Migrators must
use an approved method of encryption to protect data at rest. Mobile
devices are defined to include laptops, PDAs, and cell phones.`}</p>
    <p>{`Users are expressly forbidden from storing Data Migrators data on
devices that are not issued by Data Migrators, such as storing
Data Migrators email on a personal cell phone or PDA.`}</p>
    <h3>{`Laptops`}</h3>
    <p>{`Laptops must employ full disk encryption with an approved software
encryption package. No Data Migrators data may exist on a laptop in
plaintext.`}</p>
    <h3>{`PDAs and Cell phones`}</h3>
    <p>{`Any Data Migrators data stored on a cell phone or PDA must be saved to
an encrypted file system using Data Migrators-approved software.
Data Migrators shall also employ remote wipe technology to remotely
disable and delete any data stored on a Data Migrators PDA or cell
phone which is reported lost or stolen.`}</p>
    <h3>{`Keys`}</h3>
    <p>{`All encryption keys and pass-phrases must meet complexity requirements
described in Data Migrators’ `}<a parentName="p" {...{
        "href": "password_protection_policy"
      }}>{`Password Protection Policy`}</a>{`.`}</p>
    <h3>{`Loss and Theft`}</h3>
    <p>{`The loss or theft of any mobile device containing Data Migrators data
must be reported immediately.`}</p>
    <h2>{`Policy Compliance`}</h2>
    <h3>{`Compliance Measurement`}</h3>
    <p>{`The Infosec team will verify compliance to this policy through various
methods, including but not limited to, business tool reports, internal
and external audits, and feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec Team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <ul>
      <li parentName="ul">{`Password Protection Policy`}</li>
    </ul>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`The following definition and terms can be found in the `}<a parentName="p" {...{
        "href": "https://www.sans.org/security-resources/glossary-of-terms/"
      }}>{`SANS Glossary`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">{`Plaintext`}</li>
      <li parentName="ul">{`Full Disk Encryption`}</li>
      <li parentName="ul">{`Remote Wipe`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      